import React from 'react';
import { Container, Content, Header, Loader } from 'rsuite';
import { Router, AppWrapper } from '@airstudio/ui';
import { Authenticator, Greetings, UsernameAttributes } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';

import * as Pages from '../../pages';
import {Menu} from '../Menu';
import {Logo} from '../Logo';

const RootLoading: React.FC = () => {
  return (
    <div style={{textAlign: 'center', padding: 200}}>
      <Loader size="lg" />
    </div>
  );
};

export const Root: React.FC = () => {

  const authModule = () => {
    return (
      <div>

        <div style={{textAlign: 'center', marginTop: 100}}>
          <Logo />
        </div>

        <Authenticator
          usernameAttributes={UsernameAttributes.EMAIL}
          hide={[Greetings]}
          theme={{
            sectionHeader: {
              fontSize: 24,
              fontFamily: '\'PT Serif\', serif',

            },
            formSection: {
              boxShadow: 'none',
            },
            a: {
              color: '#3fb0ac',
            },
            button: {
              backgroundColor: '#3fb0ac',
            },
            formContainer: {
              marginTop: '60px',
            },
          }}
          signUpConfig={{
            hideAllDefaults: true,
            signUpFields: [{
              label: 'Email',
              key: 'email',
              required: true,
              displayOrder: 1,
              type: 'string',
            }, {
              label: 'Password',
              key: 'password',
              required: true,
              displayOrder: 2,
              type: 'password',
            }],
          }}
        />
      </div>
    );
  };

  return (
    <AppWrapper authentication={authModule} loading={RootLoading}>
      <Container>
        <Header>
          <Menu />
        </Header>

        <Content>
          <Router.Switch>
            <Router.Redirect path="/" exact to="/observations" />
            <Router.Route path="/observations/:id?" component={Pages.Observations} />
            <Router.Route component={Pages.NotFound} />
          </Router.Switch>
        </Content>
      </Container>
    </AppWrapper>
  );
};
