import React, { useState } from 'react';
import { Icon, Button, Input, InputGroup, Grid, Row, Col } from 'rsuite';
import { useSelector } from 'react-redux';
import { Selectors } from '@airstudio/state';
import { UserEditor } from '@airstudio/ui';
import { Link } from 'react-router-dom';
import { useResourceListQuery } from '@airstudio/ui';

import style from './style.module.scss';
import { Logo } from '../Logo';

export const Menu: React.FC = () => {

  const { isAuthenticated } = useSelector(Selectors.Session.state);
  // const dispatchLogout = Operations.Session.logout(useDispatch());
  const [isUserEditorVisible, setUserEditorVisible] = useState(false);
  const {handleSearch} = useResourceListQuery({path: '/observations'});
  const [searchValue, setSearchValue] = useState('');

  const colMiddle = (
    <InputGroup inside className={style.searchBar}>
      <InputGroup.Addon>
        <Icon icon="search" />
      </InputGroup.Addon>
      <Input
        placeholder="Search all observations, users and tags"
        value={searchValue}
        onChange={setSearchValue}
        onPressEnter={() => handleSearch(searchValue)}
      />
    </InputGroup>
  );

  return (
    <div>
      {isAuthenticated && (
        <>
          <UserEditor isVisible={isUserEditorVisible} setIsVisible={setUserEditorVisible} />
        </>
      )}

      <div className={style.topBar}>
        <Grid fluid>
          <Row>
            <Col xs={12} md={6}>
              <Button className={style.logo} appearance="subtle" componentClass={Link} to="/">
                <Logo small />
              </Button>
            </Col>

            <Col xsHidden smHidden md={12} style={{paddingTop: 10}}>{colMiddle}</Col>

            <Col xs={12} md={6} style={{textAlign: 'right'}}>
              {/*
                <Badge>
                  <IconButton icon={<Icon icon="bell" />} />
                </Badge>
              */}
              {/*
                <Button appearance="subtle" componentClass={Link} to="/">
                  username
                </Button>
              */}
            </Col>

            <Col mdHidden lgHidden>{colMiddle}</Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};
