import { Resource, Types, Router } from '@airstudio/ui';
import {ButtonGroup, Button, Avatar, Modal, Row, Col, Icon, Message} from 'rsuite';
import React from 'react';

import svgKudo from '../../assets/kudo.svg';
import svgIdea from '../../assets/idea.svg';
import svgFrustration from '../../assets/frustration.svg';
import svgBug from '../../assets/bug.svg';
import svgKudoC from '../../assets/kudo_c.svg';
import svgIdeaC from '../../assets/idea_c.svg';
import svgFrustrationC from '../../assets/frustration_c.svg';
import svgBugC from '../../assets/bug_c.svg';
import svgUser from '../../assets/user.svg';
import s from './style.module.scss';

import {Date} from '../Date';

export interface IObservationProps {
  id?: string;
}

const CC: React.FC<Types.IResourceChildProps> = (props) => {

  const history = Router.useHistory();

  if (!props.resource) {
    return (<>Loading</>);
  }

  const hideObservationPage = () => {
    history.push('/observations');
  };

  const updateHandler = (emotion: string) => {
    return () => {
      props.handleSave({ emotion, isRead: true });
    };
  };

  const isEmotion = (e: string) => {
    return props.resource.emotion && props.resource.emotion === e;
  };

  return (
    <Modal overflow={false} show={true} onHide={hideObservationPage} style={{padding: 5}}>
      <Modal.Body className={s.modal}>
        <Row className={s.header}>
          <Col xs={16} className={s.h1}>
            <span>{props.resource.fromUserName}</span>
          </Col>
          <Col xs={8} className={s.avatar}>
            <Avatar circle size="lg" src={svgUser}/>
          </Col>
        </Row>

        <div className={s.UserAttrList}>
          <div className={s.userAttr}>
            <Date datetime={props.resource.createdAt} format="MMM D, H:mm" />
          </div>

          <div className={s.userAttr}>
            <Icon icon="desktop" /> Mobile
          </div>

          <div className={s.userAttr}>
            <Icon icon="chrome" /> Chrome v78
          </div>

          <div className={s.userAttr}>
            <Icon icon="map-marker" /> Montreal, Canada
          </div>
        </div>

        <div className={s.content}>
          {props.resource.content}
        </div>

        <ButtonGroup className={s.emotionButtons} justified>
          <Button onClick={updateHandler('kudo')} disabled={props.isProcessing}>
            <img src={isEmotion('kudo') ? svgKudoC : svgKudo} alt="Kudo"/><br/>
            Kudo
          </Button>
          <Button onClick={updateHandler('idea')} disabled={props.isProcessing}>
            <img src={isEmotion('idea') ? svgIdeaC : svgIdea} alt="Idea"/><br/>
            Idea
          </Button>
          <Button onClick={updateHandler('bug')} disabled={props.isProcessing}>
            <img src={isEmotion('bug') ? svgBugC : svgBug} alt="Bug"/><br/>
            Bug
          </Button>
          <Button onClick={updateHandler('frustration')} disabled={props.isProcessing}>
            <img src={isEmotion('frustration') ? svgFrustrationC : svgFrustration} alt="Frustration"/><br/>
            Frustration
          </Button>
        </ButtonGroup>

        {props.errorMessage && (
          <Message type="error" description={props.errorMessage} style={{marginTop: 20}} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export const Observation: React.FC<IObservationProps> = ({id}) => {

  if (!id) { return (<></>); }

  return (
    <Resource path="/observations" id={id} componentClass={CC} />
  );
};
