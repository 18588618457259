import React from 'react';
import cn from 'classnames';

import style from './style.module.scss';
import icon from '../../assets/logo.svg';

export interface ILogoProps {
  small?: boolean;
}

export const Logo: React.FC<ILogoProps> = ({small}: ILogoProps = {}) => {

  return (
    <div className={cn(style.logo, {[style.logo__small]: small})}>
      <img alt="Loopback logo" src={icon} /> Loopback
    </div>
  );
};
