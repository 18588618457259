import React from 'react';
import moment from 'moment';

export interface IDateProps {
  datetime: string;
  format?: string;
}

export const Date: React.FC<IDateProps> = ({datetime, format}) => {
  const date = format
    ? moment(datetime).format(format)
    : moment(datetime).fromNow();

  return (<span>{date}</span>);
};
