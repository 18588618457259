import React from 'react';
import {FlexboxGrid, TagGroup, Tag, Col, Row} from 'rsuite';
import { IResource } from '@airstudio/ui/dist/types';
import cc from 'classnames';
import { Link } from 'react-router-dom';

import style from './style.module.scss';

import {Date} from '../Date';
import {ObservationIcon} from '../ObservationIcon';

export interface IObservationListItemProps {
  observation: IResource;
}

export const ObservationListItem: React.FC<IObservationListItemProps> = ({observation}) => {
  return (
    <div className={cc(style.o, {[style.o__new]: !observation.isRead})}>
      <Link to={`/observations/${observation.id}`}>
        <FlexboxGrid align="middle">
          <FlexboxGrid.Item className={style.left}>
            <ObservationIcon
              emotion={observation.emotion}
              classNameBlackWhite={style.bw}
              classNameColor={style.color}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className={style.right}>
            <Row>
              <Col xs={12} className={style.title}>
                <div className={style.o__user}>
                  {observation.fromUserName} ({observation.fromUserId})
                  <span className={style.o__user__sub}>
                    {/* userAttributes */}
                  </span>
                </div>
              </Col>

              <Col xs={12} style={{textAlign: 'right'}}>
                <Date datetime={observation.createdAt} />
              </Col>
            </Row>

            <div className={style.o__message}>
              {observation.content}
            </div>

            <div>
              <TagGroup>
                {observation.emotion && (
                  <Tag>{observation.emotion}</Tag>
                )}
              </TagGroup>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Link>
    </div>
  );
};
