import React from 'react';
import { useResourceListQuery, useParamsKey } from '@airstudio/ui';

import {ObservationListItem} from '../ObservationListItem';
import s from './style.module.scss';
import {Observation} from '../../components/Observation';

export const ObservationList: React.FC = () => {

  const {resources} = useResourceListQuery({path: '/observations'});
  const observationId = useParamsKey('id');

  return (
    <div className={s.list}>
      <Observation id={observationId} />

      {resources?.map((i) => (
        <div key={i.id}>
          <ObservationListItem observation={i} />
        </div>
      ))}
    </div>
  );
};
