import {InitState, Extensions} from '@airstudio/state';
import {Amplitude, SplitIO, LogRocket, Sentry, GoogleAnalytics, Mixpanel, Amplify} from '@airstudio/extensions';
import React from 'react';
import reactDom from 'react-dom';

import './theme.less';
import { Root } from './components/Root';

Extensions.Register(new Amplify());
Extensions.Register(new Sentry());
Extensions.Register(new Amplitude());
Extensions.Register(new SplitIO());
Extensions.Register(new LogRocket());
Extensions.Register(new GoogleAnalytics());
Extensions.Register(new Mixpanel());

InitState();

reactDom.render(<React.StrictMode><Root /></React.StrictMode>, document.getElementById('root'));
