import React from 'react';

import iconBug from '../../assets/bug.svg';
import iconBugC from '../../assets/bug_c.svg';
import iconFrustration from '../../assets/frustration.svg';
import iconFrustrationC from '../../assets/frustration_c.svg';
import iconIdea from '../../assets/idea.svg';
import iconIdeaC from '../../assets/idea_c.svg';
import iconKudo from '../../assets/kudo.svg';
import iconKudoC from '../../assets/kudo_c.svg';
import iconNew from '../../assets/new.svg';
import iconNewC from '../../assets/new_c.svg';

export interface IObservationIconProps {
  emotion?: string;
  classNameColor: string;
  classNameBlackWhite: string;
}

export const ObservationIcon: React.FC<IObservationIconProps> = (props: IObservationIconProps) => {

  let iconBlackWhite = iconNew;
  let iconColor = iconNewC;

  switch (props.emotion) {
    case 'bug':
      iconBlackWhite = iconBug;
      iconColor = iconBugC;
      break;
    case 'frustration':
      iconBlackWhite = iconFrustration;
      iconColor = iconFrustrationC;
      break;
    case 'idea':
      iconBlackWhite = iconIdea;
      iconColor = iconIdeaC;
      break;
    case 'kudo':
      iconBlackWhite = iconKudo;
      iconColor = iconKudoC;
      break;
  }

  return (
    <>
      <img src={iconColor} className={props.classNameColor} alt="" width={50} />
      <img src={iconBlackWhite} className={props.classNameBlackWhite} alt="" width={50} />
    </>
  );
};
